import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Layout from "./Layout"
import ProductLinkContainer from "./ProductLinkContainer"
import { SEARCH_ROUTE } from "../constants"
import { Product } from "../types"

const productLimit = 200

export default ({ location }) => {
  const productData = useStaticQuery(graphql`
    query {
      allProduct(sort: { fields: name, order: ASC }) {
        nodes {
          productImage {
            childImageSharp {
              fluid(maxWidth: 600) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          category
          name
          featured
          id
          minPrice
          path
          subcategory
        }
      }
    }
  `)
  const [query, setQuery] = React.useState<string>("")
  React.useEffect(() => {
    if (location.search) {
      const result = location.search.split("=")[1]
      if (result) {
        setQuery(decodeURI(result))
      } else {
        setQuery("")
      }
    } else {
      setQuery("")
    }
  }, [location.search])
  let products = productData.allProduct.nodes
  if (query) {
    const regEx = new RegExp(query.replace(/[|&;$%@"<>()+,\\]/g, ""), "i")
    products = products.filter(({ name, category, subcategory }) => {
      const searchString = name + category + subcategory
      return regEx.test(searchString)
    })
  }
  return (
    <Layout pageTitle="Search Results" pathname={SEARCH_ROUTE}>
      <div className="layout-container">
        <div>
          <h4 className="margin-bottom-1 margin-top-2">Search Results</h4>
          <div className="text-muted">
            <small>
              Showing{" "}
              {query ? `results for "${query}"` : "results for all products"}{" "}
            </small>
          </div>
        </div>
        <div className="padding-bottom-4">
          <div className="grid-container product-grid">
            {products.slice(0, productLimit).map((product: Product) => {
              return (
                <ProductLinkContainer
                  product={product}
                  key={product.id}
                  showCategories
                />
              )
            })}
          </div>
        </div>
      </div>
    </Layout>
  )
}
