import React from 'react';
import { Router } from '@reach/router';
import SearchPage from '../components/SearchPage';
import PrivateRoute from '../components/PrivateRoute';

export default () => {
    return (
        <Router basepath="/search">
            <PrivateRoute component={SearchPage} path="/" />
        </Router>
    )
}